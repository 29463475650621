import React, {useRef} from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import VideoModal from '../landings/_videoModal';

const ContentPage = () => {
  const videoRef = useRef(null);


  return (
    <Layout.Layout textColor="dark">
      <Layout.SEO
        refKey="mysql"
        title="MySQL Backup Service"
        // description="Automate your MySQL Backups and store them on any cloud provider. Support Incremental backup, flexible scheduling, Managed database (DBaaS) or self-hosted database."
        description="Automate your MySQL Backups and store them on any cloud provider. flexible scheduling, Managed database (DBaaS) or self-hosted database."
      />
      <Layout.Hero
        type="primary"
        title={(<>Automate & trust<br/>your <span className="text-primary">MySQL backups</span></>)}
        subTitle={(
          <>Create automatic MySQL backups without code.<br/>
            Set a schedule, a storage and let it work in peace.
          </>)}
        checkMarks={['Self-hosted database', 'DbaaS & Managed database']}
        icons={[
          <Ui.Image filename="brands/icons/mysql-ico.png" alt="MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/digitalocean-ico.png" alt="DigitalOceanMySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/aws-ico.png" alt="AWS MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/azure-ico.png" alt="Azure MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/google-cloud-ico.png" alt="Google Cloud MySQL backup" className="w-6"/>,
          <Ui.Image filename="brands/icons/more-ico.png" alt="More backup" className="w-6"/>
        ]}
        cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your MySQL Backup" urlAtrr={{ sb_source: "website", sb_term: "mysql-hero" }} />)}
        illustration={(
          <div className="absolute w-full right transform rounded-xl " onClick={() => videoRef.current.show()}>
            <span className="group absolute z-10 inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
              <div className="relative">
                <div className="rounded-full ring-indigo-200 ring-4 ring-w-20 h-20 bg-primary hover:scale-110 transform transition duration-200 cursor-pointer">
                  <svg className="h-20 w-20 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
                    <path
                      fill="white"
                      d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"></path>
                  </svg>
                </div>
              </div>
            </span>
            <Ui.Image className="" filename="simplebackups-mysql-backup-hero.png" alt="SimpleBackups MySQL backup"/>
          </div>
        )}
        withIllustrationBox={false}
      />

      <Ui.Container>
        <Sections.JoinBrandsSection />
      </Ui.Container>

      <Ui.Section>
        <Ui.Container>
          <Ui.Row>
            <Ui.FeatureRows items={
              [
                {
                  preTitle: "Optimize your database backups",
                  title: (<>Get peace of mind with <span className="text-primary">automatic MySQL backup</span> solution</>),
                  description: (
                      <>
                        <p>
                          Run your backups using optimized backups scripts built
                          by experts and with the right flags for you.
                          Stop messing around with complex documentation.
                          <Ui.FeatureChecks items={['Incremental backup', 'Large database support', 'No table lock', 'MySQL custom flags', 'Backup Streaming', 'Serverless backup']} />
                          <Ui.Link arrow="right" to="/features">View all features</Ui.Link>
                        </p>
                      </>
                  ),
                  image: ( <Ui.Image
                      className="shadow-2xl rounded-xl"
                      filename='simplebackups-app-backup-expert.png'
                      alt="Optimized MySQL backup service"
                      style={{maxWidth: 450}}
                  />)
                },
                Content_FeatureTrust({subject: 'MySQL Backups', keyRef:'mysql'}),
                Content_FeatureControl({subject: 'MySQL', keyRef:'mysql'}),
              ]}></Ui.FeatureRows>

          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="alt" id="features">
        <Ui.Container>
          <Ui.Row>
            <Ui.Typography tag="h2" className="text-center">Want to geek out on the technical details?</Ui.Typography>
            <div className="text-center">
              <p className="mb-6">SimpleBackups offers a user-friendly setup process, perfect for those who may not be tech experts. For the tech-savvy, we're eager to dive into the details, tailoring SimpleBackups to meet your unique requirements and maximize its potential for you.</p>
              <p className="py-5 text-lg font-bold">Hosted locally? Using a MySQL DBaaS service? Not a problem!</p>
              <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
                <li><i className="far fa-cloud text-green mr-2"></i>Amazon RDS</li>
                <li><i className="far fa-cloud text-green mr-2"></i>Amazon Aurora</li>
                <li><i className="far fa-cloud text-green mr-2"></i>Azure Database</li>
                <li><i className="far fa-cloud text-green mr-2"></i>cPanel MySQL</li>
                <li><i className="far fa-cloud text-green mr-2"></i>DigitalOcean Managed MySQL</li>
                <li><i className="far fa-cloud text-green mr-2"></i>Google Cloud SQL MySQL</li>
                <li><i className="far fa-cloud text-green mr-2"></i>Kamatera MySQL</li>
                <li><i className="far fa-cloud text-green mr-2"></i>MariaDB</li>
                <li><i className="far fa-cloud text-green mr-2"></i>Percona Server</li>
                <li><i className="far fa-cloud text-green mr-2"></i>OVH MySQL/MariaDB</li>
                <li><i className="far fa-cloud text-green mr-2"></i>ScaleGrid Managed MySQL</li>
              </ul>

              <p className="mt-12 py-5 text-lg font-bold">Full-featured MySQL Backup service, you’re covered:</p>
              <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">MySQL BinLog support</span>
                </li>
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">Point in time Backup restore</span>
                </li>
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">Advanced MySQL flag management</span>
                </li>
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">Backup all databases at once</span>
                </li>
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">SSL Support</span>
                </li>
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">Self-hosted connections credentials</span>
                </li>
                <li>
                  <i className="far fa-check-circle text-green mr-2"></i>
                  <span className="font-medium">Backup streaming</span>
                </li>
              </ul>
              <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>

              <Ui.Typography tag="h3" className="text-2xl text-center mt-16 mb-8">Wait... there's even more...</Ui.Typography>
              <Sections.FeatureSlider tags={["mysql"]} />
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Sections.SectionLearn
        title={<>Wanna learn more about MySQL Backups?</>}
        text="Find out more and get started with our MySQL Backup guides"
        tags={['mysql']}
      />

      <Sections.SectionGetStarted/>
      <Sections.SectionTestimonialExtended />
      {/* <Sections.SectionFaq color="alt" faqs={['what_mysql_backup_service', 'how_mysql', 'support_mysql_incremental', 'how_restore_backup', 'can_download_backup', 'use_for_client_2', 'is_secure']}/> */}
      <Sections.SectionFaq color="alt" faqs={['what_mysql_backup_service', 'how_mysql', 'how_restore_backup', 'can_download_backup', 'use_for_client_2', 'is_secure']}/>

      <VideoModal ref={videoRef} videoSrc="https://www.youtube.com/embed/vri9qLf5YHI" />
    </Layout.Layout>
  )
}
export default ContentPage
